<template>
<!--  <div>-->

<!--    <div class="kt-login__head">-->
<!--      <a :href="site_main_url" class="kt-login__logo">-->
<!--        <img src="/assets/media/logos/logo-black.png" />-->
<!--      </a>-->
<!--    </div>-->
    <!--begin::Body-->
    <div class="kt-login__body">
      <!--begin::Signin-->
      <div class="kt-login__form">
        <div class="kt-login__title">

          <h3 class="">Create your account</h3>

<!--          <p><a :href="site_main_url">Back to Website</a></p>-->
        </div>

        <!--begin::Form-->
        <b-form class="kt-form" @submit.stop.prevent="onSubmit">
          <b-form-group id="example-input-group-0" label label-for="example-input-0">
            <b-form-input
              id="example-input-0"
              name="example-input-0"
              v-model="$v.form.fname.$model"
              :state="validateState('fname')"
              aria-describedby="input-0-live-feedback"
              placeholder="First name"
              class="border"
            ></b-form-input>

            <b-form-invalid-feedback v-if="!$v.form.fname.$error" id="input-0-live-feedback">First Name is required</b-form-invalid-feedback>
            <b-form-invalid-feedback v-else>First Name is too long</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group id="example-input-group-1" label label-for="example-input-1">
            <b-form-input
              id="example-input-1"
              name="example-input-1"
              v-model="$v.form.lname.$model"
              :state="validateState('lname')"
              aria-describedby="input-1-live-feedback"
              placeholder="Last name"
              class="border"
            ></b-form-input>

            <b-form-invalid-feedback v-if="!$v.form.lname.$error" id="input-1-live-feedback">Last Name is required.</b-form-invalid-feedback>
            <b-form-invalid-feedback v-else>Last Name is too long</b-form-invalid-feedback>
          </b-form-group>
<!--          <b-form-group id="example-input-group-7" label label-for="example-input-7">-->
<!--            <b-form-select-->
<!--              id="example-input-7"-->
<!--              name="example-input-7"-->
<!--              :state="validateState('country_id')"-->
<!--              aria-describedby="input-7-live-feedback"-->
<!--              v-model="$v.form.country_id.$model"-->
<!--              value-field="id"-->
<!--              text-field="title"-->
<!--              :options="countries"-->
<!--              placeholder="Select country"-->
<!--              class="border"-->
<!--            ></b-form-select>-->

<!--            <b-form-invalid-feedback id="input-1-live-feedback">Country is required.</b-form-invalid-feedback>-->
<!--          </b-form-group>-->

          <b-form-group id="example-input-group-4" label label-for="example-input-4">
            <b-form-input
              id="example-input-4"
              name="example-input-4"
              v-model="$v.form.email.$model"
              :state="validateState('email')"
              aria-describedby="input-4-live-feedback"
              placeholder="Email address"
              class="border"
            ></b-form-input>

            <b-form-invalid-feedback
              id="input-4-live-feedback"
              v-if="$v.form.email.isUnique && !$v.form.email.required"
            >Email and must be a valid email address</b-form-invalid-feedback>

            <b-form-invalid-feedback v-if="!$v.form.email.isUnique">Email already taken.</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group id="example-input-group-5" label label-for="example-input-5">
            <b-form-input
              type="password"
              id="example-input-5"
              name="example-input-5"
              v-model="$v.form.password.$model"
              :state="validateState('password')"
              aria-describedby="input-5-live-feedback"
              placeholder="Password"
              class="border"
            ></b-form-input>

            <b-form-invalid-feedback id="input-6-live-feedback">Password is required.</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group id="example-input-group-6" label label-for="example-input-6">
            <b-form-input
              type="password"
              id="example-input-6"
              name="example-input-6"
              v-model="$v.form.confirmPassword.$model"
              :state="validateState('confirmPassword')"
              aria-describedby="input-6-live-feedback"
              placeholder="Confirm password"

              class="border"
            ></b-form-input>

            <b-form-invalid-feedback id="input-6-live-feedback">Password should match</b-form-invalid-feedback>
          </b-form-group>

          <!--begin::Action-->
          <div class="kt-login__actions">
            <b-button
              v-on:click="$router.push('login')"
              class="btn btn-text btn-elevate kt-login__btn-primary"
            >{{ $t("AUTH.GENERAL.BACK_BUTTON") }}</b-button>

            <b-button
              type="submit"
              id="kt_submit"
              class="btn btn-primary btn-elevate kt-login__btn-primary text-white kt-spinner--light kt-spinner--right"
            >{{ $t("AUTH.REGISTER.TITLE") }}</b-button>
          </div>
          <!--end::Action-->
        </b-form>
        <!--end::Form-->
        <!--begin::Head-->
        <div class="kt-login__head">
          <span class="kt-login__signup-label">Already have an account?</span>&nbsp;&nbsp;
          <router-link class="kt-link kt-login__signup-link" :to="{ name: 'login' }">Login!</router-link>
        </div>
        <!--end::Head-->
      </div>
      <!--end::Signin-->
      <vue-recaptcha
        ref="invisibleRecaptcha"
        :sitekey="sitekey"
        :loadRecaptchaScript="true"
        size="invisible"
      ></vue-recaptcha>
      <vue-snotify></vue-snotify>
    </div>
    <!--end::Body-->
<!--  </div>-->
</template>

<script>
import { mapState } from "vuex";
import { REGISTER } from "@/store/auth.module";
import { LOGOUT, SET_LOGIN } from "@/store/auth.module";
import ApiService from "@/common/api.service";
import { validationMixin } from "vuelidate";
import { email, required, minLength,maxLength, sameAs } from "vuelidate/lib/validators";
import VueRecaptcha from "vue-recaptcha";
/* import VueSocialauth from "vue-social-auth"; */
import { isNullableMobileNumber } from "@/common/validation";
export default {
  components: { VueRecaptcha },
  mixins: [validationMixin],
  name: "register",
  data() {
    return {
      sitekey: process.env.VUE_APP_GOOGLE_CAPTCHA_SITE_KEY,
      site_main_url: process.env.VUE_APP_SERVER_URL,
      countries: [],
      form: {
        fname: "",
        lname: "",
        email: "",
        password: "",
        // country_id: "",
        confirmPassword: "",
      },
    };
  },
  validations: {
    form: {
      fname: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      lname: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      // country_id: {
      //   required,
      // },
      email: {
        required,
        email,
        async isUnique(value) {
          if (value === "") return true;

          return new Promise((resolve, reject) => {
            setTimeout(() => {
              ApiService.post("user/validate/email", {
                email: this.$v.form.email.$model,
              }).then((response) => {
                resolve(response.data);
              });
            }, 500);
          });
        },
      },

      password: {
        required,
        minLength: minLength(3),
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null,
        fname: null,
        lname: null,
      };
      this.$nextTickf(() => {
        this.$v.$reset();
      });
    },
    getCountries() {
      ApiService.get("api/countries").then((response) => {
        this.countries = response.data;
        this.countries.splice( 0,0,{id: "", title: 'Please Select Country' })
        Object.values(response.data).forEach((value) => {
          if (value.prefix == "NPL") {
            this.form.country_id = value.id;
          }
        });
      });
    },
    onSubmit() {
      this.$refs.invisibleRecaptcha.execute();
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const fname = this.$v.form.fname.$model;
      const lname = this.$v.form.lname.$model;
      const email = this.$v.form.email.$model;
      // const country_id = this.$v.form.country_id.$model;
      const password = this.$v.form.password.$model;

      // set spinner to submit button
      const submitButton = document.getElementById("kt_submit");
      submitButton.classList.add("kt-spinner");

      // dummy delay
      setTimeout(() => {
        ApiService.post("user/register", {
          first_name: fname,
          last_name: lname,
          email: email,
          // country_id: country_id,
          password: password,
          is_active: 0,
        })
          .then((response) => {
            this.$router.push({ name: "verification" });
          })
          .catch((err) => {
            if (err.response.status == 422) {
              this.$snotify.error(err.response.data.message);
            }
          });

        submitButton.classList.remove("kt-spinner");
      }, 2000);
    },
    AuthProvider(provider) {
      var self = this;
      this.$auth
        .authenticate(provider)
        .then((response) => {
          self.SocialLogin(provider, response);
        })
        .catch((err) => {});
    },

    SocialLogin(provider, response) {
      ApiService.get("/sanctum/csrf-cookie").then(() => {
        this.$http
          .post("/sociallogin/" + provider, response)
          .then((response) => {
            this.$store
              .dispatch(SET_LOGIN, response.data)
              .then(() => this.$router.push({ name: "dashboard" }));
          })
          .catch((err) => {});
      });
    },
    redirectIfLoggedIn() {
      if (this.$store.getters.isAuthenticated) {
        this.$router.push({ name: "dashboard" });
      }
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/bg/bg-2.jpg";
    },
  },
  created: function () {
    this.getCountries();
  },
  mounted: function () {
    this.redirectIfLoggedIn();
  },
};
</script>

<style>
  .btn-secondary{
    color: gray !important;
  }
  .btn-primary{
    color: white !important;
  }
</style>
